import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledCausesList = styled.div`
  background-color: ${(props) => props.color};
  @media (${BP.ipad}) {
    padding-top: 20px; 
    background-color-no: #FFF9EC;
    border-radius: 20px;
  }
`;

export const StyledCausesListTitle = styled.div`
  text-align: center;
  margin-bottom: ${Rem(50)};
  margin-top: ${Rem(5)};
  font-size: ${Rem(38)};
  line-height: ${Rem(52)};
  color: ${Colors.black};
  @media (${BP.ipad}) {
    font-size: ${Rem(42)};
    line-height: ${Rem(54)};
  }
`;

export const StyledCausesListItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  &:after {
    content: '';
    display: block;
    min-width: ${Rem(50)};
    max-width: ${Rem(50)};
    height: 1px;

    @media (${BP.ipad}) {
      display: none;
    }
  }
`;

export const StyledCausesListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: ${Rem(50)};

  @media (${BP.ipad}) {
    margin: initial;
    width: 100%;
  }
`;

export const StyledCausesListImage = styled.img`
  width: ${Rem(80)};
  margin-bottom: ${Rem(40)};
`;

export const StyledCausesListLabel = styled.div`
  text-align: center;
  font-size: ${Rem(15)};
  line-height: ${Rem(18)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;
  font-weight: 100;
  min-width: 150px;
`;

export const StyledCausesListMobilePopup = styled.div`
  position: absolute;
  width: calc(100vw - ${Rem(40)});
  top: auto;
  left: 50%;
  transform: translate(-50%, 0);
  color: ${Colors.white};
  background-color: ${Colors.gray};
  padding: ${Rem(16)};
  z-index: 2;
  opacity: 1;
  pointer-events: all;

  &:empty {
    opacity: 0;
    pointer-events: none;
  }

  @media (${BP.ipad}) {
    display: none;
  }
`;

export const StyledCausesListPopup = styled.div`
  opacity: 1;
  pointer-events: none;
  width: 100%;
  height: auto;
  font-
  top: 50%;
  font-weight: 100;
  text-align: center;
  left: 0;
  padding: 0px ${Rem(2)};
  @media (${BP.ipad}) {
    display: block;
    width: 80%;
    padding: 0px ${Rem(10)};
  }
`;

export const StyledCausesLine = styled.div`
    border-top: 1.5px solid #AAA9A9;
    display: block;
    margin: 15px 0px;
    width: 100px;
    @media (${BP.ipad}) {
      width: 140px;
    }
`
