import React from "react";
import {
  StyledCausesList,
  StyledCausesListTitle,
  StyledCausesListItems,
  StyledCausesListItem,
  StyledCausesListImage,
  StyledCausesListLabel,
  StyledCausesListPopup,
  StyledCausesLine,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";

export default ({ title, items, color }) => {

  return (
    <div style="position: relative; margin: auto; max-width: 1230px;">
      <StyledCausesList color={color} id="causeslist">
        <StyledCausesListTitle dangerouslySetInnerHTML={{ __html: title }}/>
        <Scroller>
          <StyledCausesListItems>
            {items.map((item, index) => (
              <StyledCausesListItem
                key={index}
              >
                <StyledCausesListImage src={item.image} loading="lazy" />
                <StyledCausesListLabel
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
                <StyledCausesLine/>
                <StyledCausesListPopup
                  dangerouslySetInnerHTML={{ __html: item.popup }}
                />
              </StyledCausesListItem>
            ))}
          </StyledCausesListItems>
        </Scroller>
      </StyledCausesList>
      </div>
  );
};
